<template>
<!-- 积分详情 -->
  <div class="integral">
    <div class="integral_list" v-for="item in list" :key="item">
      <div style="padding-top:10px">{{item.StatusName}} : {{item.Score}}分</div>
      <div style="font-size:14px">{{item.AddTime}}</div>
      <div style="font-size:14px;padding-bottom:15px">{{item.Content}}</div>
    </div>
    <div v-if="hidden">
      还没有积分记录~
    </div>
  </div>
</template>
<script>
import { setOpenId, getOpenId } from "@/utils/auth";
import { WxScoreDetailList,WeGetMemberInfo } from "@/api/yeguang";
export default {
  data(){
    return{
      list:[],
      name:"",
      hidden:false,
    }
  },
  created(){
    this.ScoreDetailList();
  },
  methods:{
    // getOpenId()
    ScoreDetailList(){
      WeGetMemberInfo({openID:getOpenId()}).then((res)=>{
        this.name = res.data.Name;
      })
      WxScoreDetailList({openID:getOpenId()}).then((res)=>{
        this.list = res.data.data;
        for(var i=0; i<this.list.length; i++){
          if(this.list[i].Status == 1){
            this.list[i].StatusName = "增加积分"
          }else{
            this.list[i].StatusName = "扣除积分"
          }
        }
        // if(this.list)
      })
    }
  }
}
</script>
<style>
.integral_list {
  width: 90%;
  margin: 10px 5%;
  background: rgb(241, 237, 237);
  border-radius: 10px;
}
.integral_list div {
  padding: 3px 15px;
}
</style>